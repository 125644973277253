import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Content, { HTMLContent } from "../components/Content"
import "./program.css"
import ContactButtons from "../components/ContactButtons/ContactButtons"

export const ProgramPageTemplate = ({
  content,
  contentComponent,
  field,
  header,
  programHtml,
  helmet,
  fb_link,
  email,
  telephone,
}) => {
  const PageContent = contentComponent || Content

  return (
    <>
      <section>
        <div className="program-page-container">
          {field && (
            <div className="field-special program-disclaimer">
              <p>{field}</p>
              <ContactButtons
                className="contact-buttons"
                telephone={telephone}
                email={email}
                fb_link={fb_link}
                fb_btn={`przez facebook'a!`}
                main_bg="var(--blue-color)"
                action_bg="var(--pink-color)"
                border="1px solid var(--bg-color)"
              />
            </div>
          )}
          <h2 className="page-header">{header}</h2>
          <PageContent
            content={content}
            className="program-content content-disclaimer"
          />
          <PageContent
            content={programHtml}
            className="program-content main-program-content"
          />
        </div>
      </section>
    </>
  )
}

const ProgramPage = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark
  const { programHtml } = data.programMarkdown
  const { contact } = data.contactMarkdown

  return (
    <Layout location={location} seoTitle="Program Szkoły Rodzenia">
      <ProgramPageTemplate
        content={html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${frontmatter.title}`}</title>
            <meta name="description" content={`${frontmatter.description}`} />
          </Helmet>
        }
        header={frontmatter.header}
        field={frontmatter.field}
        programHtml={programHtml}
        {...contact}
      />
    </Layout>
  )
}

export default ProgramPage

export const ProgramPageQuery = graphql`
  query ProgramPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        field
        header
      }
      html
    }
    programMarkdown: markdownRemark(
      frontmatter: { template: { eq: "programszkoly" } }
    ) {
      programHtml: html
    }
    contactMarkdown: markdownRemark(
      frontmatter: { template: { eq: "contact" } }
    ) {
      contact: frontmatter {
        telephone
        email
        fb_link
      }
    }
  }
`
