import React, { useState } from "react"
import ActionButton from "../ActionButton/ActionButton"
import fb from "../../images/facebook_s.svg"
import mail from "../../images/Mail.svg"
import insta from "../../images/instagram_s.svg"
import phone from "../../images/phone.svg"
import Anchor from "../../components/anchor"

const ContactButtons = ({
  email,
  fb_link,
  fb_btn,
  insta_link,
  insta_btn,
  main_bg,
  action_bg,
  telephone,
  border,
  className,
}) => {
  const [copied, setCopied] = useState(false)
  const copyToClipboard = text => {
    const input = document.body.appendChild(document.createElement("input"))
    input.value = text
    input.select()
    document.execCommand("copy")
    input.parentNode.removeChild(input)
  }
  return (
    <div className={className}>
      {email && (
        <div style={{ position: "relative" }}>
          <ActionButton
            icon={mail}
            text={copied ? "email skopiowany!" : email}
            color={copied ? action_bg : main_bg}
            border={border}
            action={() => {
              setCopied(true)
              copyToClipboard(email)
              setTimeout(() => setCopied(false), 600)
            }}
          />
        </div>
      )}
      {fb_link && (
        <Anchor anchor={fb_link}>
          <ActionButton
            icon={fb}
            text={fb_btn}
            color={main_bg}
            border={border}
          />
        </Anchor>
      )}
      {insta_link && (
        <Anchor anchor={insta_link}>
          <ActionButton
            icon={insta}
            text={insta_btn}
            color={main_bg}
            border={border}
          />
        </Anchor>
      )}
      {telephone && (
        <Anchor anchor={`tel:+48${telephone}`}>
          <ActionButton
            icon={phone}
            text={telephone}
            color={main_bg}
            border={border}
          />
        </Anchor>
      )}
    </div>
  )
}

export default ContactButtons
